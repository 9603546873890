import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After processing over 2.4 billion messages, we wanted to take a look at how some of the metrics have changed over time.`}</p>
    <p>{`One question that still comes up is around buttons vs free form communication, especially as platforms add more app like functionality.`}</p>
    <p>{`We first wrote about this after processing 70 million messages.`}</p>
    <p>{`Let’s take a look at the latest insights.`}</p>
    <p>{`The beauty of conversational interfaces is they enable users to communicate naturally in free form — to say what they want from a bot and what they think of a bot in their own words and voice. It’s the natural evolution of human computer interaction.`}</p>
    <p>{`Limiting the functionality in this medium to just button clicks can have a negative effect on user experience.`}</p>
    <p>{`When we previously looked at buttons and quick replies vs free form conversation in Facebook, we saw there was a significant drop off in engagement, as measured by sessions per user per month, when the percentage of buttons and quick replies became higher than the average.`}</p>
    <p>{`Looking at the first three months of the year, we see an even more pronounced drop off. The average percentage of messages across bots that are buttons and quick replies rose to about 50%. Bots with higher percentages tend to experience significantly less engagement.`}</p>
    <p>{`Across Facebook bots in general, if the percentage of buttons and messages is more than one standard deviation above the average, the engagement is about half the engagement when it’s one standard deviation below the average.`}</p>
    <p><strong parentName="p">{`People like to chat!`}</strong></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07130000/0_NGBF6dwLJgQimJ98.png)
    </figure>
Some categories tend to have significantly higher engagement with free form
communication over buttons.
    <p>{`Social makes the most sense — if you’re chatting with a social bot, including dating bots, it’s better to allow free form communication. Bots that skew more heavily to buttons in this category have significantly less engagement. In fact, for bots with button and quick reply percentages less than the average, the sessions per user per month are about 11 times greater than for bots above the average.`}</p>
    <p>{`Reference bots and Fitness bots also do much better when they allow for more free form communication. Reference bots have about 6.5 times the sessions per user per month when less than the average percentage of buttons vs above. Health `}{`&`}{` Fitness bots have about 4 times more.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125959/0_2VncbTIX0RmYyIs2.png)
    </figure>
One of the most clear cases when buttons have higher engagement is in Games.
Games on the platform tend to fit into two extremes — word based games and
button based. The engagement on button based ones is about 3 times greater.
    <p>{`Shopping and Entertainment (movies, videos, etc) also tend to have higher engagement as the percentage of buttons and quick replies increases. In each case, the engagement is about 1.5 times greater in bots with buttons and quick reply percentages above the average vs below.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125959/0_uDqb35FW5lAmA1Nx.png)
    </figure>
The right combination of free form messaging vs buttons and quick replies can
depend on the usecase. Categories like Travel, Sports, and Insurance tend to
have higher engagement with the right mixture of buttons and quick replies vs
free form text.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125958/0_w3K3bZtJyr71oo5p.png)
    </figure>
It’s important to monitor the usage of your bot — reviewing the analytics can
help you increase user engagement and retention.
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      